import axios from 'axios';

const state = {
    projects: [],
    project: null,
    salesUsers: [],
    filteredData: {
        status: [],
        user: [],
        name: ''
    },
    sortedData: {
        field: '',
        direction: ''
    }
};

const mutations = {
    setUsers(state, payload) {
        state.salesUsers = payload;
    },
    setFilteredData(state, payload) {
        state.filteredData.status = payload.status;
        state.filteredData.user = payload.user;
        state.filteredData.name = payload.name;
    },
    setSortedData(state, payload) {
        state.sortedData.field = payload.field;
        state.sortedData.direction = payload.direction;
    }
};

const actions = {
    async setUsers({ commit }) {
        const response = await axios.get('/projects/sales_representatives', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        });
        commit('setUsers', response.data);
    },

    async updateProjectStatus({ commit }, payload) {
        await axios.put('/projects/', payload, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    
    saveProject({ commit, dispatch }, projectData) {
        return new Promise((resolve, reject) => {
            const project = {
                'name': projectData.name,
                'number': projectData.number,
                'sales_representative': projectData.sales_representative,
                'user': projectData.user,
                'password': projectData.password,
                'zipcode': projectData.zipcode,
                'city': projectData.city,
                'country': projectData.country,
                'status': projectData.status
            };
            if (projectData._id) {
                project['_id'] = projectData._id;
            }
            if (projectData.is_deleted) {
                project['is_deleted'] = true;
            }
            axios.post('/projects/', project, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                resolve(res);
            }).catch(error => {
                reject(error.response);
            });
        })
    },
    getProjects({ commit, dispatch }, data) {
        return axios.get('/projects/', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    getProject({ commit, dispatch }, id) {
        return axios.get('/projects/' + id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    getReport({ commit, dispatch }, id) {
        return axios.get('/reports/project/' + id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    uploadData({ commit, dispatch }, id) {
        return axios.get('/projects/upload_to_dam/' + id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
};

const getters = {
    getProjects(state) {
        return state.projects
    },
    projectData(state) {
        return state.project
    },
    getSalesUsers(state) {
        return state.salesUsers;
    },
    getFilteredData(state) {
        return state.filteredData;
    },
    getSortedData(state) {
        return state.sortedData;
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
