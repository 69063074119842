<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
</style>

<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('tryAutoLogin')
  }
}
</script>
