import axios from 'axios';

const state = {
    users: [],
    user: null
};

const mutations = {};

const actions = {
    saveUser({ commit, dispatch }, userData) {
        return new Promise((resolve, reject) => {
            const user = {
                'email': userData.email,
                'name': userData.name,
                'status': userData.status
            };
            if (userData._id) {
                user['_id'] = userData._id;
            }
            if (userData.password) {
                user['password'] = userData.password;
            }
            axios.put('/users', user, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                resolve(res);
            }).catch(error => {
                reject(error.response);
            });
        })
    },
    getUsers() {
        return axios.get('/users/', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
    getActiveUsers() {
        return axios.get('/users?is_active=1', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
    },
};

const getters = {
    getUsers(state) {
        return state.users
    },
    userData(state) {
        return state.user
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
