import Vuex from "vuex";

import login from "./modules/login";
import user from "./modules/user";
import project from '@/store/modules/project';


export default new Vuex.Store({
                                  modules: {
                                      login: login,
                                      user: user,
                                      project: project
                                  }
                              });
