
import { defineComponent } from 'vue';

export default defineComponent({
                                 name: 'Login',
                                 data() {
                                   return {
                                     model: {
                                       email: '',
                                       password: ''
                                     },
                                     error: false,
                                     errorMessage: "",
                                     loading: false,
                                     showInput: false
                                   }
                                 },
                                 methods: {
                                   login() {
                                     this.loading = true;
                                     const formData = {
                                       email: this.model.email,
                                       password: this.model.password
                                     };
                                     this.$store.dispatch('signIn', formData).then(() => {
                                       this.loading = false;
                                       this.$router.push('/projects')
                                     }).catch((error) => {
                                       this.loading = false;
                                       this.error = true;
                                       this.errorMessage = "Ungültiger login oder passwort";
                                     });
                                   },
                                   switchPasswordView() {
                                     this.showInput = !this.showInput;
                                   }
                                 }
                               });
