import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Auth/Login.vue';
import Admin from '../components/Admin.vue';


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "login",
        component: Login,
        meta: {
            title: 'Login'
        },
        beforeEnter(to, from, next) {
            if (localStorage.getItem('token')) {
                next('/projects');
            } else {
                next();
            }
        }
    },
    {
        path: "/",
        redirect: "projects",
        component: Admin,
        children: [
            {
                path: "/projects",
                name: "Projects",
                meta: {
                    title: 'Projectliste'
                },
                component: () => import( "../views/Project/Projects.vue")
            },
            {
                path: "/projects/project",
                name: "Create Project",
                meta: {
                    title: 'Neuer Projekt'
                },
                component: () => import( "../views/Project/Project.vue")
            },
            {
                path: "/projects/project/:id",
                name: "Edit Project",
                meta: {
                    title: 'Neuer Projekt'
                },
                component: () => import( "../views/Project/Project.vue")
            },
            {
                path: "/users",
                name: "Users",
                meta: {
                    title: 'Benutzer'
                },
                component: () => import( "../views/User/Users.vue")
            },
            {
                path: '/users/user',
                name: "Create User",
                meta: {
                    title: 'Neuer Benutzer'
                },
                component: () => import( "../views/User/User.vue")
            }
        ],
        beforeEnter(to, from, next) {
            if (localStorage.getItem('token')) {
                next()
            } else {
                next('/')
            }
        }
    }
]

const router = createRouter({
                                history: createWebHashHistory(),
                                linkExactActiveClass: "active",
                                routes
                            })

export default router
